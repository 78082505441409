import { makeVar } from '@apollo/client';

export const loadSummary = makeVar(false);
export const loadCartItem = makeVar(false);
export const loadGiftCart = makeVar(false);
export const loadRewardPoint = makeVar(false);
export const loadStoreCredit = makeVar(false);
export const emailGuest = makeVar('');

export const defaultSwiftPromoVar = {
    freeGifts: {
        ruleId: '',
        skus: [],
        selectedItems: [],
        initCartItems: [],
        maxQty: 0,
        totalItems: 0,
    },
};

export const swiftPromoVar = makeVar(defaultSwiftPromoVar);

export const defaultCheckout = {
    isGuest: false,
    addresses: [],
    shippingAddress: null,
    billingAddress: null,
    shippingMethods: null,
    selectedShippingMethods: null,
    selectedPaymentMethods: null,
    deliveryMethod: 'home',
    promoCode: null,
    giftCartUsed: [],
    gifCartList: [],
    appliedExtraFee: null,
    rewardPoint: null,
    appliedRewardPoint: null,
    storeCredit: null,
    appliedStoreCredit: null,
    selectedStore: null,
    pickupInformation: null,
    isVirtual: false,
    loading: {
        all: true,
        promo: false,
    },
    error: {
        email: false,
        shippingAddress: false,
        billingAddress: false,
        shippingMethod: false,
        paymentMethod: false,
        pickupInformation: false,
    },
    cart: {},
    member: null,
    swiftPromoList: [],
    subscription: {
        hasSet: false,
        data: {},
    },
};

export const checkoutVar = makeVar({
    isGuest: false,
    addresses: [],
    shippingAddress: null,
    billingAddress: null,
    shippingMethods: null,
    selectedShippingMethods: null,
    selectedPaymentMethods: null,
    deliveryMethod: 'home',
    promoCode: null,
    giftCartUsed: [],
    gifCartList: [],
    appliedExtraFee: null,
    rewardPoint: null,
    appliedRewardPoint: null,
    storeCredit: null,
    appliedStoreCredit: null,
    isVirtual: false,
    loading: {
        all: true,
        promo: false,
    },
    error: {
        email: false,
        shippingAddress: false,
        billingAddress: false,
        shippingMethod: false,
        paymentMethod: false,
        pickupInformation: false,
    },
    cart: {},
    member: null,
    selectedStore: null,
    pickupInformation: null,
    ewalletPoints: 'REDEEM_NONE',
    swiftPromoList: [],
    subscription: {
        hasSet: false,
        data: {},
    },
});

export default {
    checkoutVar,
};
