const crypto = require('crypto');
const {
    encryption: { algorithm },
} = require('../../swift-server.config');

const encrypt = (text, key) => {
    try {
        const iv = key.substr(0, 16);
        const cipher = crypto.createCipheriv(algorithm, key, iv);
        let crypted = cipher.update(text, 'utf8', 'base64');
        crypted += cipher.final('base64');
        return crypted;
    } catch (error) {
        return '';
    }
};

const decrypt = (text, key) => {
    try {
        const iv = key.substr(0, 16);
        const decipher = crypto.createDecipheriv(algorithm, key, iv);
        let dec = decipher.update(text, 'base64', 'utf8');
        dec += decipher.final('utf8');
        return dec;
    } catch (error) {
        return '';
    }
};

const createHash = (text, algo = 'sha256') => {
    if (text) {
        const textToString = typeof text === 'string' ? text.toLowerCase() : text;
        const hash = crypto.createHash(algo).update(textToString).digest('hex');
        return hash;
    }
    return '';
};

module.exports = {
    encrypt,
    decrypt,
    createHash,
};
